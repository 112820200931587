import React from "react";

function Loader() {
    return (
        <div className="load-bar">
            <div className="bar" />
            <div className="bar" />
            <div className="bar" />
        </div>
    );
}

export default Loader;
