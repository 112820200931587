/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Formik } from "formik";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import { TbRefresh } from "react-icons/tb";
import { BsCheckAll } from "react-icons/bs";
import { db } from "./firebaseConfig";


export default function CategoryForm() {
  const templateCollection = collection(db, "type");

  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");

  // Form Validations
  const initialValues = {
    name: "",
    imageUrl: "",
  };
  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.title = "Field is required!";
    }
    if (values.imageUrl) {
      if (!values.imageUrl.includes(".com")) {
        errors.imageUrl = "Invalid url!";
      }
    } else {
      errors.imageUrl = "Invalid url!";
    }

    return errors;
  };
  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => validate(values)}
      onSubmit={async (values, { resetForm }) => {
        console.log(values);
        setLoader(true);
        const entryDetails = {
          name: values.name,
          imageUrl: values.imageUrl,
        };

        await addDoc(templateCollection, entryDetails)
          .then(() => {
            resetForm();
            setLoader(false);
            setMessage("Added Successfully!")
            setTimeout(() => {
                setMessage("")
            },2000)
          })
          .catch((err) => console.log(err));
      }}
    >
      {({ values, errors, touched, handleChange, handleSubmit }) => (
        <div>
          <h3 className="text-center mt-6 text-lg">New Data Upload</h3>
          <div className="form">
            <form onSubmit={handleSubmit}>
              <div className="field padding-bottom--24">
                <label htmlFor="title">
                  Name{" "}
                  <span className="error">{errors.title && errors.title}</span>
                </label>
                <input
                  type="text"
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                />
              </div>
              <div className="field padding-bottom--24">
                <div className="grid--50-50">
                  <label htmlFor="imageUrl">
                    ImageUrl{" "}
                    <span className="error">
                      {errors.imageUrl && errors.imageUrl}
                    </span>
                  </label>
                </div>
                <input
                  type="text"
                  name="imageUrl"
                  onChange={handleChange}
                  value={values.imageUrl}
                />
              </div>
              <div className="field padding-bottom--24 pt-4 w-80  flex center-center ">
                <input
                  type="submit"
                  name="Add New"
                  defaultValue="Continue"
                  value="Add New"
                />
                {loader && (
                  <div className="text-white ml-2 text-xl circle_spinner">
                    <TbRefresh />
                  </div>
                )}
              </div>
              {message && (
                <div className="text-white flex center-center text-2xl">
                  <BsCheckAll /> <span className="text-sm">{message}</span>
                </div>
              )}
            </form>
          </div>
        </div>
      )}
    </Formik>
  );
}
