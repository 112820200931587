import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import LeftNavMenuItem from "./LeftNavMenuItem";
import { categories } from "../utils/constants";
import { Context } from "../context/contextApi";

function LeftNav() {
    const { selectedCategory, setSelectedCategory, quality, setQuality, mobileMenu } =
        useContext(Context);

    const qualityCategories = [{
        name: "All",
        slug: "all",
        type: "category",
        imgUrl: "https://yt3.ggpht.com/ytc/AL5GRJUyrP_g56id_nbsxdbelsxAufX2ReqWbJvYbgg87LY=s68-c-k-c0x00ffffff-no-rj"
    },
    {
        name: "480p",
        slug: "480p",
        type: "category",
        imgUrl: "https://yt3.ggpht.com/ytc/AL5GRJUyrP_g56id_nbsxdbelsxAufX2ReqWbJvYbgg87LY=s68-c-k-c0x00ffffff-no-rj"
    },
    {
        name: "Trending",
        slug: "480p",
        type: "category",
        imgUrl: "https://yt3.ggpht.com/ytc/AL5GRJUyrP_g56id_nbsxdbelsxAufX2ReqWbJvYbgg87LY=s68-c-k-c0x00ffffff-no-rj"
    },
    {
        name: "1080p",
        slug: "480p",
        type: "category",
        imgUrl: "https://yt3.ggpht.com/ytc/AL5GRJUyrP_g56id_nbsxdbelsxAufX2ReqWbJvYbgg87LY=s68-c-k-c0x00ffffff-no-rj"
    },
    ]

    console.log(quality)

    const navigate = useNavigate();

    const clickHandler = (name, type) => {
        switch (type) {
            case "category":
                return setSelectedCategory(name);
            case "home":
                return setSelectedCategory(name);
            case "menu":
                return false;
            default:
                break;
        }
        return false
    };

    const qualityHandler = (name, type) => {
        setQuality(name)
        switch (type) {
            case "category":
                return setSelectedCategory(name);
            case "home":
                return setSelectedCategory(name);
            case "menu":
                return false;
            default:
                break;
        }
        return false

    };

    console.log(quality)

    return (
        <div
            className={`md:block w-[240px] overflow-y-auto h-auto py-4 bg-black absolute md:relative z-10 translate-x-[-240px] md:translate-x-0 transition-all ${mobileMenu ? "translate-x-0" : ""
                } scroll`}
        >
            <div className="flex px-5 flex-col">
                {categories.map(item => (
                    <React.Fragment key={item.name}>
                        <LeftNavMenuItem
                            text={item.name}
                            icon="https://yt3.ggpht.com/ytc/AL5GRJUyrP_g56id_nbsxdbelsxAufX2ReqWbJvYbgg87LY=s68-c-k-c0x00ffffff-no-rj"
                            action={() => {
                                clickHandler(item.name, item.type);
                                navigate("/");
                            }}
                            className={`${selectedCategory === item.name
                                ? "bg-white/[0.10]"
                                : ""
                                }`}
                        />
                    </React.Fragment>
                ))}
                <hr className="my-5 border-white/[0.2]" />
                <div className="text-white text-sm cursor-pointer h-10 flex items-center px-3 mb-[1px] rounded-lg"
                    style={{ backgroundColor: "rgb(87 87 87 / 15%)" }}
                >Quality
                </div>

                {qualityCategories.map(item => (
                    <React.Fragment key={item.name}>
                        <LeftNavMenuItem
                            text={item.name}
                            icon={item.imgUrl}
                            action={() => {
                                qualityHandler(item.name, item.type);
                                navigate("/");
                            }}
                            className={`${selectedCategory === item.name
                                ? "bg-white/[0.10]"
                                : ""
                                }`}
                        />
                    </React.Fragment>
                ))}

                <hr className="my-5 border-white/[0.2]" />
                <div className="text-white/[0.5] text-[12px]">
                    Clone by: JS Dev Hindi
                </div>
            </div>
        </div>
    );
}

export default LeftNav;

