/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/prefer-default-export */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from '@firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCiG_6v6SWVZM46jiJAygM7vSqx9K43PsU",
  authDomain: "test-app-522a4.firebaseapp.com",
  projectId: "test-app-522a4",
  storageBucket: "test-app-522a4.appspot.com",
  messagingSenderId: "640416829225",
  appId: "1:640416829225:web:aadfa5408261a0e5967138"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db  = getFirestore(app);