/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";

function LeftNavMenuItem({ text, icon, className, action }) {
    return (
        <div
            className={
                `text-white text-sm cursor-pointer h-10 flex items-center px-3 mb-[1px] rounded-lg hover:bg-white/[0.08] ${ 
                className}`
            }
            onClick={action}
        >
            <div className="text-xl mr-5">
                <img src={icon} alt=""
                    width="25"
                    height="25"
                    style={{ borderRadius: "50%" }} />
            </div>
            {text}
        </div>
    );
}

export default LeftNavMenuItem;
