/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/media-has-caption */
import React,{ useState } from "react";
import AWS from "aws-sdk";
import LeftNav from "./LeftNav";
import UploadForm from "./UploadForm";

export default function Upload() {
  const fileInput = document.querySelector(".file-input");

  const [src, setSrc] = useState("");

  AWS.config.update({
    region: "ap-south-1",
    accessKeyId: "AKIARGN27IEAD56K64AD",
    secretAccessKey: "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",
  });

  const s3 = new AWS.S3();
  const bucketName = "unlayer-test-gallery";
  const bucketUrl = "https://unlayer-test-gallery.s3.ap-south-1.amazonaws.com";

  const inputChangeHandler = async (e) => {
    const file = e.target.files[0];
    const fileName = file.name;
    const fileType = file.type;

    setSrc({
      type: fileType,
      src: URL.createObjectURL(file),
    });

    console.log({
      type: fileType,
      src: URL.createObjectURL(file),
    });

    const s3Params = {
      Bucket: bucketName,
      Key: fileName,
      Body: file,
      ContentType: fileType,
      ACL: "public-read",
    };

    const fileUrl = `${bucketUrl}/${fileName}`;
    // try {
    //   await s3.upload(s3Params).promise();
    //   console.log(`File uploaded successfully. ${fileUrl}`);
    //   // toast("Image Uploaded Successfully.");
    //   navigator.clipboard.writeText(fileUrl);
    //   // setTimeout(() => {
    //   //     toast("Url copied to clipboard.");
    //   // }, 500)
    //   // setIsUploading(false)
    //   // imageInput.current.value = ""
    //   // getObjects();
    // } catch (error) {
    //   console.log(`Error uploading file: ${error}`);
    // }
  };

  const uploadFile = (name) => {
    //     let xhr = new XMLHttpRequest();
    //   xhr.open("POST", "php/upload.php");
    //   xhr.upload.addEventListener("progress", ({loaded, total}) =>{
    //     let fileLoaded = Math.floor((loaded / total) * 100);
    //     let fileTotal = Math.floor(total / 1000);
    //     let fileSize;
    //     (fileTotal < 1024) ? fileSize = fileTotal + " KB" : fileSize = (loaded / (1024*1024)).toFixed(2) + " MB";
    //     let progressHTML = `<li class="row">
    //                           <i class="fas fa-file-alt"></i>
    //                           <div class="content">
    //                             <div class="details">
    //                               <span class="name">${name} • Uploading</span>
    //                               <span class="percent">${fileLoaded}%</span>
    //                             </div>
    //                             <div class="progress-bar">
    //                               <div class="progress" style="width: ${fileLoaded}%"></div>
    //                             </div>
    //                           </div>
    //                         </li>`;
    //     uploadedArea.classList.add("onprogress");
    //     progressArea.innerHTML = progressHTML;
    //     if(loaded == total){
    //       progressArea.innerHTML = "";
    //       let uploadedHTML = `<li class="row">
    //                             <div class="content upload">
    //                               <i class="fas fa-file-alt"></i>
    //                               <div class="details">
    //                                 <span class="name">${name} • Uploaded</span>
    //                                 <span class="size">${fileSize}</span>
    //                               </div>
    //                             </div>
    //                             <i class="fas fa-check"></i>
    //                           </li>`;
    //       uploadedArea.classList.remove("onprogress");
    //       uploadedArea.insertAdjacentHTML("afterbegin", uploadedHTML);
    //     }
    //   });
    //   let data = new FormData(form);
    //   xhr.send(data);
  };

  return (
    <div className="flex flex-row h-[calc(100%-56px)]">
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
      />
      <LeftNav />
      <div
        className="w-full h-fit"
        style={{ backgroundColor: "#000000f2", padding: "1rem" }}
      >
        <h1 className="text-white text-center">Select File to Upload</h1>
        <center>
          <div className="wrapper">
            <div className="form" action="#">
              {!src ? (
                <><input
                  className="file-input"
                  type="file"
                  name="file"
                  onChange={inputChangeHandler}

                />
                  <i className="fas fa-cloud-upload-alt" />
                  <p>Browse File to Upload</p>
                </>
              ) :
                src.type.includes("image") ?

                  <img src={src.src} alt="" />

                  :
                  <video className="p-2.5" controls >
                    <source src={src.src} type="video/mp4" />
                  </video>
              }

            </div>
            {/* {src && src.type.includes("image") && (
              <div className="form output">
                <img src={src.src} alt="" />
              </div>
            )}

            {src && !src.type.includes("image") &&
              <div className="form output">
                <video className="p-2.5" controls >
                  <source src={src.src} type="video/mp4" />
                </video>
              </div>} */}

            <section className="progress-area">
              <li className="row">
                <i className="fas fa-file-alt" />
                <div className="content">
                  <div className="details">
                    <span className="name">name • Uploading</span>
                    <span className="percent">70%</span>
                  </div>
                  <div className="progress-bar">
                    <div className="progress" style={{ width: "75%" }} />
                  </div>
                </div>
                <i className="fas fa-check" />
              </li>
            </section>
            {/* <section class="uploaded-area"></section> */}
          </div>

          <UploadForm />
        </center>
      </div>
    </div>
  );
}
