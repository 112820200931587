/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable import/named */
/* eslint-disable no-unused-vars */
import React, { useState , useEffect } from "react";

import { Formik } from "formik";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosClose, IoIosAdd } from "react-icons/io";
import { BsCheckAll } from "react-icons/bs";
import { TbRefresh } from "react-icons/tb";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "./firebaseConfig";

import CategoryForm from "./CategoryForm";

export default function UploadForm() {
  const [dropdown, setDropdown] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [primaryCategory, setPrimaryCategory] = useState("");
  const [searchKey, setSearchKey] = useState(" ");
  const [categoryRefresh, setCategoryRefresh] = useState(false);
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);

  const CategoryCollection = collection(db, "type");
  const VideoCollection = collection(db, "test-doc");

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      const data = await getDocs(CategoryCollection);
      setCategories(
        data.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
          .map((item) => ({
            id: item.id,
            title: item.name,
            imageUrl: item.imageUrl,
            primary: false,
            state: false,
          }))
      );
    };
    getUsers();
  }, []);

  // Form Validations
  const initialValues = {
    title: "",
    url: "",
    duration: "",
    thumburl: "",
    category: "",
    primary: "",
  };
  const validate = (values) => {
    const errors = {};
    if (!values.title) {
      errors.title = "Field is required!";
    }
    if (!values.duration) {
        errors.duration = "Field is required!";
      }
    if (values.url) {
      if (!values.url.includes(".com")) {
        errors.url = "Invalid url!";
      }
    } else {
      errors.url = "Invalid url!";
    }

    if (values.thumburl) {
      if (!values.thumburl.includes(".com")) {
        errors.thumburl = "Invalid url!";
      }
    } else {
      errors.thumburl = "Invalid url!";
    }

    if (!primaryCategory) {
      errors.category = "Select the Category";
    }

    return errors;
  };

  const onCategorySelect = (id) => {
    setDropdown(false);
    categories.forEach((item) => {
      if (item.id === id) {
        item.state = true;
        item.primary = true;
      } else {
        item.primary = false;
      }
    });
    setSelectedCategories(categories.filter((item) => item.state));
    setPrimaryCategory(
      categories.filter((item) => item.state && item.primary)[0]
    );
  };

  const onPrimaryCategorySelect = (id) => {
    selectedCategories.forEach((item) =>
      item.id === id ? (item.primary = true) : (item.primary = false)
    );
    setPrimaryCategory(
      categories.filter((item) => item.state && item.primary)[0]
    );
  };

  const onCategoryDelete = (id) => {
    categories.forEach((item) => (item.id === id ? (item.state = false) : ""));
    setSelectedCategories(categories.filter((item) => item.state));
    setPrimaryCategory(
      categories.filter((item) => item.state && item.primary)[0]
    );
  };

  const onCategoryRefresh = async () => {
    setCategoryRefresh(true);
    await getDocs(CategoryCollection).then((response) => {
      setCategories(
        response.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
          .map((item) => ({
            id: item.id,
            title: item.name,
            imageUrl: item.imageUrl,
            primary: false,
            state: false,
          }))
      );
      setCategoryRefresh(false);
    });
  };
  const onChangeDropDown = async (e) => {
    setSearchKey(e.target.value);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    const templateDetails = {
      type: "test",
      imgUrl: "eyru",
      videoUrl: "7436",
      cat: selectedCategories,
    };

    await addDoc(CategoryCollection, templateDetails)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <Slider {...settings}>
        <div>
          <Formik
            initialValues={initialValues}
            validate={(values) => validate(values)}
            onSubmit={async (values, { resetForm }) => {
                setLoader(true)
              const entryDetails = {
                ...values,
                category: selectedCategories.map((item) => ({
                  id: item.id,
                  title: item.title,
                  imageUrl: item.imageUrl,
                })),
                primary: {
                  id: primaryCategory.id,
                  title: primaryCategory.title,
                  imageUrl: primaryCategory.imageUrl,
                },
              };

                await addDoc(VideoCollection, entryDetails)
                  .then(() => {
                    resetForm();
                    setSearchKey("")
                    setSelectedCategories([])
                    setPrimaryCategory("")
                    setLoader(false);
                    setMessage("Added Successfully!");
                    setTimeout(() => {
                      setMessage("");
                    }, 2000);
                  })
                  .catch((err) => console.log(err));
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <div>
                <h3 className="text-center mt-6 text-lg">New Data Upload</h3>
                <div className="form">
                  <form id="stripe-login" onSubmit={handleSubmit}>
                    <div className="field padding-bottom--24">
                      <label htmlFor="title">
                        Title{" "}
                        <span className="error">
                          {errors.title && errors.title}
                        </span>
                      </label>
                      <input
                        type="text"
                        name="title"
                        onChange={handleChange}
                        value={values.title}
                      />
                    </div>
                    <div className="field padding-bottom--24">
                      <div className="grid--50-50">
                        <label htmlFor="url">
                          URL{" "}
                          <span className="error">
                            {errors.url && errors.url}
                          </span>
                        </label>
                      </div>
                      <input
                        type="text"
                        name="url"
                        onChange={handleChange}
                        value={values.url}
                      />
                      {console.log(errors)}
                    </div>
                    <div className="field padding-bottom--24">
                      <div className="grid--50-50">
                        <label htmlFor="duration">
                          Duration{" "}
                          <span className="error">
                            {errors.duration && errors.duration}
                          </span>
                        </label>
                      </div>
                      <input
                        type="text"
                        name="duration"
                        onChange={handleChange}
                        value={values.duration}
                      />
                    </div>
                    <div className="field padding-bottom--24">
                      <div className="grid--50-50">
                        <label htmlFor="thumburl">
                          Thumb URL{" "}
                          <span className="error">
                            {errors.thumburl && errors.thumburl}
                          </span>
                        </label>
                      </div>
                      <input
                        type="text"
                        name="thumburl"
                        onChange={handleChange}
                        value={values.thumburl}
                      />
                    </div>
                    <div className="field">
                      <div className="grid--50-50">
                        <label htmlFor="category" className="flex items-center">
                          Category{" "}
                          <span
                            className={`ml-1 cursor-pointer text-base ${
                              categoryRefresh ? "circle_spinner" : ""
                            }`}
                            onClick={onCategoryRefresh}
                          >
                            <TbRefresh />
                          </span>
                          <span className="error">
                            {" "}
                            {errors.category && errors.category}
                          </span>
                        </label>
                        <label htmlFor="category">
                          {primaryCategory?.title}
                        </label>
                      </div>
                      <input
                        type="text"
                        name="category"
                        className="mb-1 cursor-pointer"
                        onClick={() => setDropdown(!dropdown)}
                        onChange={onChangeDropDown}
                      />
                      {dropdown ? (
                        <div className="dropdown">
                          {categories.map(
                            (item) =>
                              !item.state &&
                              item.title
                                .toLowerCase()
                                .includes(searchKey.toLowerCase()) && (
                                <div
                                  className="dropdown-item"
                                  onClick={() => onCategorySelect(item.id)}
                                >
                                  <div className="dropdown-img-container">
                                    <img
                                      src="https://hfn-strapi-bucket.s3.ap-south-1.amazonaws.com/Stephen_Covey_7c56313b01.jpg"
                                      alt=""
                                    />
                                    <span>{item.title}</span>
                                  </div>
                                  <span className="dropdown-icon">
                                    <IoIosAdd />
                                  </span>
                                </div>
                              )
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {selectedCategories.length && !dropdown ? (
                        <div className="selected-cat-div">
                          {categories.map(
                            (item) =>
                              item.state && (
                                <div
                                  className="selected-cat-container"
                                  onClick={() =>
                                    onPrimaryCategorySelect(item.id)
                                  }
                                  style={{
                                    border: item.primary
                                      ? "1px dashed #5469d4"
                                      : "",
                                  }}
                                >
                                  <div className="selected-img-container">
                                    <img
                                      src="https://hfn-strapi-bucket.s3.ap-south-1.amazonaws.com/Stephen_Covey_7c56313b01.jpg"
                                      alt=""
                                    />
                                    <span>{item.title}</span>
                                  </div>
                                  <span
                                    className="selected-icon"
                                    onClick={() => onCategoryDelete(item.id)}
                                  >
                                    <IoIosClose />
                                  </span>
                                </div>
                              )
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    {message && (
                      <div className="text-white flex center-center text-2xl mt-6">
                        <BsCheckAll />{" "}
                        <span className="text-sm">{message}</span>
                      </div>
                    )}
                    {loader && (
                      <div className="text-white ml-2 text-xl circle_spinner mt-6">
                        <TbRefresh />
                      </div>
                    )}

                    <div className="field padding-bottom--24 pt-8">
                      <input
                        type="submit"
                        name="submit"
                        defaultValue="Continue"
                      />
                    </div>
                  </form>
                </div>
              </div>
            )}
          </Formik>
        </div>
        <div>
          <CategoryForm />
        </div>
      </Slider>
  );
}
